// For Scss : -
// //-- helpers/variables
  //-- helpers/mixins
  //-- base/spacings
  //-- base/common
  //-- components/header
  //-- components/menu
  //-- pages/landing-1
  //-- pages/landing-2
  //-- pages/landing-3
  //-- pages/landing-4
  //-- pages/contact
  //-- pages/about
  //-- pages/careerdetails
  //-- pages/career-page
  //-- pages/case-study
  //-- pages/pricing-pages
  //-- pages/blog-pages
  //-- pages/contact-pages
  //-- pages/thank-you
  //-- pages/404
  //-- pages/terms-page


/*********** CSS TABLE OF CONTENTS *******************

|--- 0.Common
|--- 1.Header
|--- 2.Menu
|--- 3.Landing 1
|--- 4.Landing 2
|--- 5.Landing 3
|--- 6.Landing 4
|--- 7.Contact
|--- 8.About
|--- 9.Career Details
|--- 10.Career Page
|--- 11.Case Study
|--- 12.Pricing Pages
|--- 13.Pricing Pages
|--- 14.Contact Pages
|--- 15.Thank You
|--- 16.404
|--- 17.Terms page

******************************************************/


@import 'helpers/variables';
@import 'helpers/mixins';
@import 'base/spacings';


/*==== 0.Common -------*/ 
@import 'base/common';

/*==== 1.Header -------*/ 
@import 'components/header';

/*==== 2.Menu -------*/ 
@import 'components/menu';

/*==== 3.Landing 1 -------*/ 
@import 'pages/landing-1';

/*==== 4.Landing 2 -------*/ 
@import 'pages/landing-2';

/*==== 5.Landing 3 -------*/ 
@import 'pages/landing-3';

/*==== 6.Landing 4 -------*/ 
@import 'pages/landing-4';

/*==== 7.Contact -------*/ 
@import 'pages/contact';

/*==== 8.About -------*/ 
@import 'pages/about';

/*==== 9.Career Details -------*/ 
@import 'pages/careerdetails';

/*==== 10.Career Page -------*/ 
@import 'pages/career-page';

/*==== 11.Case Study -------*/ 
@import 'pages/case-study';

/*==== 12.Pricing Pages -------*/ 
@import 'pages/pricing-pages';

/*==== 13.Pricing Pages -------*/ 
@import 'pages/blog-pages';

/*==== 14.Contact Pages -------*/ 
@import 'pages/contact-pages';

/*==== 15.Thank You -------*/ 
@import 'pages/thank-you';

/*==== 16.404 -------*/ 
@import 'pages/404';

/*==== 17.Terms page -------*/ 
@import 'pages/terms-page';