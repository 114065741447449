.thank-page{
  

  .thank-page-main{
    padding-top: 55px;
      padding-bottom: 70px;
    @include tablet{
      padding-top: 110px;
      padding-bottom: 140px;
    }
    .contents{
      text-align: center;
      .content-icon{
        width: 118px;
        height: 118px;
        background-color: #5454d4;
        border-radius: 500px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 48px;
      } 
      .content-text{
        .title{
          color: #19191b;
          font-weight: 700;
          letter-spacing: -2.5px;
          margin-bottom: 12px;
          font-size: 34px;
          line-height: 46px;
          @include tablet{
            font-size: 80px;
          line-height: 84px;
          }
        }
        p{
          color: #696871;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: -0.66px;
          line-height: 39px;
        }
      }
      .content-btn{
        padding-top: 18px;
        .btn{
          min-width: 250px;
          min-height: 60px;
          border-radius: 10px;
          border: 1px solid #c31a12;
          color: #f04037;
          font-size: 21px;
          font-weight: 500;
          letter-spacing: -0.66px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding-left: 20px;
          padding-right: 20px;
          transition: .4s;
          &:hover{
            background: #c31a12;
            color: #ffff;
          }
        }
      }
    }
  }
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
  .footer-section {
    background-color: #19191b;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #ffffff;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }

}

