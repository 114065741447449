.case-study-page{
  .page-banner{
    padding-top: 25px;
      @include tablet{
        padding-top: 100px;
        padding-bottom: 80px;
      }
    .banner-content{
      .title{
        color: #19191b;
        font-weight: 700;
        letter-spacing: -2.5px;
        padding-bottom: 22px;
        font-size: 34px;
        line-height: 46px;
        @include tablet{
          font-size: 80px;
          line-height: 84px;
        }
      }
      p{
        color: #696871;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
        margin-top: 5px;
      }
    }
  }
  .case-study-card{
    transition: .4s;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    .card-img{
      border-top-left-radius:8px;
      border-top-right-radius:8px;
      border-bottom-right-radius:0px;
      border-bottom-left-radius:0px;
      overflow: hidden;
      position: relative;
      >img{
        width: 100%;
        @include mobile-lg{
          width: auto;
        }
      }
      .brand-img {
        position: absolute;
        bottom: 28px;
        left: 30px;
      }
    }
    .card-content{
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 35px;
      padding-right: 35px;
      border-left: 1px solid #eae9f2;
      border-right: 1px solid #eae9f2;
      border-bottom: 1px solid #eae9f2;
      background-color: #ffffff;
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      .title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        margin-bottom: 13px;
      }
      p{
        color: #767581;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 26px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &:hover{
      box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    }

    &-2{
      transition: .4s;
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      @include mobile{
        display: flex;
      }
    .card-img{
      border-radius:8px;
      overflow: hidden;
      position: relative;
      @include tablet{
        max-width: 210px;
      }
      >img{
        width: 100%;
        @include mobile-lg{
          width: auto;
        }
      }
    }
    .card-content{
      padding-top: 30px;
      @include mobile{
        padding-left: 30px;
        padding-top: 0px;
      }
      .brand-img{
        margin-bottom: 22px;
      }
      .title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        margin-bottom: 13px;
      }
      p{
        color: #767581;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 26px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  }

  .cta-section {
    background-color: $l1-primary-heading;
    position: relative;
    @include large-desktops{
      padding-top: 150px;
      padding-bottom: 80px;
    }
    .shape-1 {
      position: absolute;
      top: 0;
      left: 0px;
    }
    .shape-2 {
      position: absolute;
      top: 0;
      right: -270px;
    }
    .section-title {
      .title {
        color: #FFF;
        margin-bottom: 35px;
      }
      p {
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 40px;
      }
    }
    .cta-btn{
      padding-top: 5px;
      .btn{
        width: 250px;
        height: 60px;
        border-radius: 10px;
        border: 1px solid #c31a12;
        background-color: #f04037;
        color: #fff;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.66px;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .footer-section {
    background-color: #19191b;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #ffffff;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }

  &.case-study-details{
    .case-study-details-section{
      border-bottom: 1px solid #eae9f2;
      padding-bottom: 50px;
      @include tablet{
        padding-bottom: 120px;
      }
    }
    .details-text-block{
      .title{
        color: #19191b;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        line-height: 28px;
        padding-bottom:22px ;
      }
      p{
        color: #696871;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 28px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .details-list{
        li{
          color: #696871;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -0.5px;
          line-height: 28px;
          margin-left: 20px;
          margin-bottom: 17px;
          display: flex;
          &:last-child{
            margin-bottom: 0;
          }
          &::before{
            content: "";
            min-width: 10px;
            max-width: 10px;
            min-height: 10px;
            max-height: 10px;
            background-color: #19191b;
            display: block;
            border-radius:500px ;
            margin-right: 15px;
            margin-top: 10px;
          }
        }
      }
      .btn{
        min-width: 250px;
        min-height: 60px;
        border-radius: 10px;
        border: 1px solid #c31a12;
        background-color: #f04037;
        overflow: hidden;
      }
    }
  }
}