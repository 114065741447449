.terms-page{
  
.terms-contents{
  .title-main{
    color: #19191b;
    font-weight: 700;
    letter-spacing: -2.5px;
    font-size: 34px;
    line-height: 46px;
    @include tablet{
      font-size: 80px;
    line-height: 84px;
    }
  }
  .title-block{
    color: #19191b;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
  }
  p{
    color: #696871;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 28px;
  }
  .term-list{
    li{
      color: #696871;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 24px;
      display: flex;
      margin-bottom: 25px;
      &:before{
        content: "";
        min-width: 9px;
        max-width: 9px;
        min-height: 9px;
        max-height: 9px;
        background-color: #19191b;
        border-radius: 500px;
        display: inline-block;
        margin-right: 13px;
        margin-top: 9px;
      }
    }
  }
}
  
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
  .footer-section {
    background-color: #19191b;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #ffffff;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }

}

