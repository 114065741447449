.contact-page{
  .site-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
    .inner-hero-area{
      padding-top: 100px;
      padding-bottom: 50px;
      @include tablet{
        padding-top: 190px;
      padding-bottom: 180px;
      }
      &.bg-gradient{
        background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
      }
      .inner-hero-text{
        .title{
          color: #19191b;
          font-weight: 700;
          letter-spacing: -2.5px;
          line-height: 1;
          margin-bottom: 15px;
          font-size: 34px;
          line-height: 46px;
          @include tablet{
            margin-bottom: 32px;
            font-size: 80px;
          }
        }
        p{
          color: #696871;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: -0.66px;
          line-height: 39px;
        }
      }
     
    }
    .page-banner{
      padding-top: 20px;
      padding-bottom: 30px;
      @include tablet{
        padding-top: 100px;
        padding-bottom: 80px;
      }
      .banner-content{
        .title{
          color: #19191b;
          font-weight: 700;
          letter-spacing: -2.5px;
          font-size: 34px;
          line-height: 46px;
          padding-bottom: 12;
          @include tablet{
            font-size: 80px;
            line-height: 84px;
            padding-bottom: 22px;
          }
        }
        p{
          color: #696871;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: -0.66px;
          line-height: 39px;
          margin-top: 5px;
        }
      }
    }

    .section-title{
      margin-bottom: 50px;
      @include desktops{
        margin-bottom: 80px;
      }
      h2{
        color: #19191b;
        font-size: 60px;
        font-weight: 700;
        letter-spacing: -1.88px;
        line-height: 70px;
      }
    }
  .omega-contact-form{
    .form-group{
      margin-bottom: 30px;
      .form-control{
        margin-bottom: 0;
      }
      label{
        color: #19191b;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.56px;
        line-height: 1;
        margin-bottom: 20px;
      }
    }
    .form-title{
      color: #19191b;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;

    }
    .form-control{
      border-radius: 10px;
      border: 1px solid #eae9f2;
      background-color: #ffffff;
      color: #9c9aaa;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      margin-bottom: 17px;
      text-indent: 25px;
    }
    input{
      min-height: 73px;
    }
    textarea{
      min-height: 154px;
      max-height: 154px;
      padding-top: 20px;
    }
    .btn{
      width: 100%;
      max-height: 60px;
      min-height: 60px;
      &.w-small{
        max-width: 255px;
      }
    }
  }

  .contact-widget-wrapper{
    border-radius: 10px;
    background-color: #f7f7fb;
    padding-top: 80px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    @include desktops{
      padding-left: 140px;
      padding-right: 150px;
    }
    @include large-desktops{
      padding-left: 150px;
      padding-right: 150px;
    }
  }
.contact-widget-block{
  .title{
    color: #19191b;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
    line-height: 1
  }
  p{
    color: #696871;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 39px;
    margin-bottom:0;
  }
}
  .contact-form-title--big{
    .form-title{
      color: #19191b;
      font-weight: 700;
      letter-spacing: -2.5px;
      font-size: 34px;
      line-height: 46px;
      margin-bottom: 15px;
      @include tablet{
        font-size: 80px;
        line-height: 84px;
        margin-bottom: 30px;
      }
    }
    p{
      color: #696871;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
    }
  }

  .contact-map {
    
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
    @include desktops{
      margin-top: 0px;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 47%;
    }
  }
  .footer-section {
    background-color: #19191b;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #ffffff;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }


}