.about-page{
  .section-title{
    p{
      margin-top:28px;
    }
    
  }
.site-header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
  .inner-hero-area{
    padding-top: 95px;
    padding-bottom: 50px;
    @include tablet{
      padding-top: 190px;
      padding-bottom: 180px;
    }
    &.bg-gradient{
      background-image: linear-gradient(147deg, rgba(141, 141, 236, 0.17) 0%, rgba(84, 84, 212, 0) 100%);
    }
    .inner-hero-text{
      .title{
        color: #19191b;
        font-weight: 700;
        letter-spacing: -2.5px;
        line-height: 1;
        font-size: 34px;
        line-height: 46px;
        margin-bottom: 15px;
        @include tablet{
          margin-bottom: 32px;
          font-size: 80px;
          line-height: 84px;
        }
      }
      p{
        color: #696871;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
      }
    }
   
  }


  /*>>>>>>>> content area styling <<<<<<<<<*/
.content-area{
  padding-top: 40px;
  padding-bottom: 40px;
 @include tablet{
  padding-top: 120px;
  padding-bottom: 110px;
 }
  .section-title{
   
    margin-bottom: 65px;
    @include desktops{
      display: flex;
      justify-content: space-between;
    }
    .title{
      color: #19191b;
      font-weight: 700;
      letter-spacing: -2.5px;
      font-size: 34px;
      line-height: 46px;
      @include tablet{
        font-size: 80px;
        line-height: 84px;
      }
      @include desktops{
        margin-right: 40px;
      max-width: 46%;

      }
    }
    p{
      color: #696871;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      @include desktops{
        margin-top: 0;
      }
    }
    .right-side{
      @include desktops{
        max-width: 40%;
        display: flex;
        align-items: flex-end;
      }
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  .content-img{
    max-height: 515px;
    // box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
  }
  .content-img-mobile{
    // box-shadow: 0 42px 54px rgba(0, 0, 0, 0.31);
  }
}

 /*>>>>>>>> Team area styling <<<<<<<<<*/
 
.team-widget{
  text-align: center;
  .widget-image{
    width: 160px;
    height: 160px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 29px;
  }
  .widget-text{
    .title{
      color: #19191b;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.75px;
      line-height: 1;
    }
    .subtitle{
      padding-top: 9px;
      color: #696871;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height:1;
      // text-transform: uppercase;
    }
  }
}
  /*>>>>>>>> Footer area styling <<<<<<<<<*/
  .footer-section {
    background-color: #19191B;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #FFFFFF;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }





  .cta-section {
    background-color: $l1-primary-heading;
    position: relative;
    .shape-1 {
      position: absolute;
      top: 0;
      left: 0px;
    }
    .shape-2 {
      position: absolute;
      top: 0;
      right: -270px;
    }
    .section-title {
      .title {
        color: #FFF;
        margin-bottom: 35px;
      }
      p {
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 40px;
      }
    }
    .cta-btn{
      padding-top: 5px;
      .btn{
        width: 250px;
        height: 60px;
        border-radius: 10px;
        border: 1px solid #c31a12;
        background-color: #f04037;
        color: #fff;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.66px;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }



  .footer-section {
    background-color: #19191b;
    .footer-top {
      padding: 80px 0 60px;
    }
  }
  .footer-title {
    color: #ffffff;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
  }
  .footer-list {
    a {
      color: rgba(255, 255, 255, 0.7);
      font-family: $l1-primary-font;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 38px;
    }
  }
  .copyright-area{
    border-top: 1px solid #2F2F31;
    padding: 15px 0;
    p{
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: -0.41px;
      line-height: 38px;
      font-family: $l1-primary-font;
    }
    .copyright-text{
      margin-bottom: 0;
    }
    .social-icons{
      display: inline-flex;
      
      li{
        a{
          color: #FFFFFF;
          font-size: 16px;
          transition: .4s;
          padding: 0 3px;
          margin: 0 2.5px;
          &:hover{
            color: $l1-secondary-color;
          }
        }
      }
    }
  }
}